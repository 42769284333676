import PropTypes from 'prop-types';
import styled from 'styled-components';

const variants = {
  default: '#E64EFF',
  gradient: 'url(#gradient)',
};

const SVG = styled.svg`
  path {
    stroke-width: 4;
  }
`;

export const CheckMark = ({ variant, ...props }) => (
  <SVG viewBox="0 0 24 20" fill="none" {...props}>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" stopColor="#FF8A00" />
      <stop offset="100%" stopColor="#E64EFF" />
    </linearGradient>
    <path d="M2 10.2949L7.87156 16.1935L22 2" stroke={variants[variant]} />
  </SVG>
);

CheckMark.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(variants)),
};

CheckMark.defaultProps = {
  width: '24',
  height: '20',
  variant: 'default',
};
